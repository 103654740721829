.register-screen {
    width: 95%;
    height: 100vh;
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    background: white;
  }
  
  .register-screen__form {
    width: 380px;
    padding: 1.5rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    /* background: #fff; */
    background: white;
    border-radius: 16px;
    border: 1px solid #dbdbdb;
  }
  
  .register-screen__title {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .register-screen__subtext {
    font-size: 0.7rem;
    display: block;
    margin: 0.5rem 0;
  }