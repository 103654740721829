.navbar {
  background-color: #363951;
  /* #e73213 */
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #dbdbdb; */
  color: white;
  
}

.navbar-right{
  margin-right: 40px;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 1rem;
  background: none;
  color: white;
 padding-top: 7px;
text-decoration: none;

}

.barIcon{

}

.nav-menu {
  /* background-color: #9dbeb7; */
  background-color: #363951;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  border-right: 1px solid #dbdbdb;
  z-index: 2;
  color: white;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;

  color: white;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: white;
  color: #363951;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #363951;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}
