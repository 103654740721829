@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500&display=swap');
  .card{
    font-family: 'Ubuntu', sans-serif; 
    min-height: 136px;
    border: 1px solid #dbdbdb;
    max-width: 640px;
    cursor: pointer;
    width:95%;
    /* height: 168px; */
    display: flex;
    flex-direction: row;
    margin: 0 0 24px;
    /* background-color: #c6dce6; */
    /* efe6d5 */
    border-radius: 16px;
    /* color: #9dbeb7; */
    color: #333;

   
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }
  .card4{
    font-family: 'Ubuntu', sans-serif; 
    /* height: 136px;
    border: 1px solid #dbdbdb; */
    max-width: 640px;
    /* cursor: pointer; */
    width:95%;
    /* height: 168px; */
    display: flex;
    flex-direction: column;
    align-self: center;
    /* margin: 0 0 24px; */
    /* background-color: #c6dce6; */
    /* efe6d5 */
    border-radius: 16px;
    /* color: #9dbeb7; */
    color: #333;
    /* margin-top: 20px; */
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
   
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }
  .card--img{
    
    height: 100%;
    /* margin: 10px; */
    border-radius: 16px;
    background-color: #918E9B;  
  }
  .card4--img{
    
    max-width: 100%;
    
    background-color: #918E9B;  
    border-radius: 16px 16px 0 0 ;
  }
  .row{
    display: flex;
    flex-direction: row;
  }
  .card--info{
    padding: 6px 12px 0  16px;
    width: 100%;
  }
  .card--google{
    margin-left: 10px;
  
    /* font-family: 'Inter';
    font-style: normal;
    font-weight: 400; */
    font-size: 10.24px;
    line-height: 12px;
    /* identical to box height */
    
    text-decoration-line: underline;
    
    color: #918E9B;
  }
  .card--location{
    /* font-family: 'Inter';
  font-style: normal;
  font-weight: 400; */
  font-size: 10.24px;
  line-height: 12px;
  letter-spacing: 0.17em;
  
  color: #2B283A;
  }
  .card--title{
    
    margin-bottom: 10px;
    display: flex;
  
    /* font-family: 'Inter';
  font-style: normal;
  font-weight: 700; */
  font-size: 21px;
  font-weight: 500;
  line-height: 30px;
  
  /* color: #2B283A; */
  }
  .card--date{
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
  
    /* font-family: 'Inter';
  font-style: normal;
  font-weight: 700; */
  font-size: 10.24px;
  line-height: 12px;
  
  color: #2B283A;
  }
  .card--description{
    display: flex;
    /* text-align: start;
    
    font-family: 'Inter';
    font-style: normal;*/
    font-weight: 400; 
    font-size: 14px;
    line-height: 150%;
    /* or 15px */
    margin-bottom: 5px;
    
    
    /* color: #2B283A; */
  }
.card--align-right{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

