.tips--card{
    color: red;
    position: sticky;
    bottom: 2%;
    /* left: 2%; */
    margin: auto;
    width: 95%;
    max-width: 640px;
    /* max-height: 180px; */
    border: 4px solid #dbdbdb;
    border-radius: 16px;
    color: #333;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    background: white;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
}
.tips--title{
    font-weight: 500;
    margin-top: 0.5rem;
    text-transform: uppercase;
    align-self: center;
    text-align: center;
}
.tips--tip{
    text-align: center;
    margin: 0 1rem 0.5rem 1rem;
}
.tips--progress{
    align-self: stretch;
    width: 100%;
    background-color: rgb(219, 219, 219);
}
.tips--progress--1{
    width: 33%;
    background-color: #363951;
    color: rgb(219, 219, 219);
    text-align: center;
}
.tips--progress--2{
    width: 67%;
    background-color: #363951;
    color: rgb(219, 219, 219);
    text-align: center;
}
.tips--progress--3{
    width: 100%;
    background-color: #363951;
    color: rgb(219, 219, 219);
    text-align: center;
}

.tips--buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.tips--button{
    padding: 5px 10px;
    margin: 7px 10px;
    border-radius: 16px;
    background-color: #363951;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
}
.closeIcon{
    position: absolute;
    right: 0px;
    cursor: pointer;
    color:#363951;
}