* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


/* @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300&display=swap');
body {
  /* font-family: Arial, Helvetica, sans-serif;*/
 
  font-family: 'Ubuntu', sans-serif;
  /* font-family: 'Roboto', sans-serif; */
  line-height: 1.7;
  /* background: #365150; */

  color: #333;

 

}


.form-group,
.form-group label,
.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  margin-bottom: 0.5rem;
  font-family: 'Roboto', sans-serif;
}

.form-group label {
  font-size: 0.8rem;
}

.form-group input,
.form-group textarea,
.form-group select {
  padding: 10px 20px;
  border: none;
  border-bottom: 3px solid transparent;
  background-color: #eee;
  outline-width: 0;
  font-size: 1rem;
}

.form-group input:focus {
  border-bottom: 3px solid coral;
}

.sort-by{
  display: flex;
  flex-direction: row;
}
.sort-by-form {
  /* width: 470px; */
  /* padding: 1.5rem; */
  /* box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); */
  /* background: #fff; */
  padding: 0;
  padding-bottom: 20px;
  /* background: white; */
  /* border-radius: 16px; */
  width: 90%;
  max-width: 640px;
  margin: auto;

  /* margin-bottom: 40px; */
}
.btn-sort-by{
  padding: 2px 0px;
  margin: 0px 5px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  border: none;
  margin-top: 0px;
}


.btn {
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  border: none;
  margin-top: 20px;
}

.submitButtonInfinity{
  margin: 18.5px 10px;
  display: block;

}
.previewImage{
  /* margin-top: 15px; */
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.previewImageUpload{
  margin-top: 20px;
}
.btn:active{
  background-color: red;
}

.btn:hover {
  opacity: 0.8;
}

.btn-primary {
  /* background-color: coral; */
  /* background-color: #3EB489; */
  background-color: #363951;
  color: #fff;
}

.error-message {
  width: 100%;
  display: inline-block;
  padding: 5px;
  background: red;
  color: #fff;
  text-align: center;
  margin: 0.5rem 0;
}

.success-message {
  width: 100%;
  display: inline-block;
  padding: 5px;
  background: green;
  color: #fff;
  text-align: center;
  margin: 0.5rem 0;
}
.gallery, .upload{
  width: 90%;
  margin:auto;
  display: flex;
  flex-direction: column;
  
  align-items: center;
}
.custom-btn{
  width: 100px;
  margin: 30px 10px;
}
.title{
  font-size: 24px;
  font-weight: 500;
  padding:  16px 0;
}
.input--image{
  display: none;
}
.input--image--icon{
  /* margin: auto; */
  text-align: center;
}
.text--align{
  text-align: center;
  font-weight: 400;
  font-size: 17px;
  width: 95%;
  margin: 20px 0;
}
.icon--close{
  position: absolute;
  margin: 20px;
  z-index: 1;
}

.twoButtons{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 20px;
 
}
.card4--btn{
  width: 40%;
  border-radius: 16px;
}

.border--radius{
  border-radius: 16px;
}
.form--update{
    /* width: 470px; */
    padding: 1.5rem;
    /* box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); */
    /* background: #fff; */
    margin: auto;
    background: white;
    /* border-radius: 16px; */
    /* margin-bottom: 40px; */
    max-width: 640px;
    width:95%;
}
.flex--row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.toyTypeRow{
  padding: 10px 0;
  
  border-bottom: 1px solid #80808080;
}
.toyTypeInput{
  margin-top: 20px;
}
.pointer{
  cursor: pointer;
}
.settings--comp{
  max-width: 640px;
  min-width: 300px;
  width: 90%;
  margin: 1rem auto;
}
.frontPage--section1{
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
}
.frontPage--section1--img1{
  width: 50%;
  margin: 40px;
  border-radius: 16px;
}
.frontPage--section1--text1{
  width: 50%;
  margin: 80px 40px;
}
.frontPage--section2{
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
}
.frontPage--badgeContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  margin: auto;
  padding-top:  45px;
  padding-bottom: 0;
  /* border: #363951 solid 2px; */
}
.google--play--badge{
  width: 300px;
  margin: 0;
  padding: 0;
}
.frontPage--section2--img1{
  width: 50%;
  margin: 40px;
  border-radius: 16px;
}
.frontPage--section2--text1{
  width: 50%;
  margin: 80px 40px;
}
.frontPage--header{
  width: 100%;
  display: flex;
  margin: auto;
  background-size: 100%;
  background-repeat: no-repeat;

  /* border-radius: 16px; */
}
.frontPage--header--logo{
  position: absolute;
  top: 100px;
  left: 100px;
  color: black;
}

.frontPage--section3--block--first, .frontPage--section3--block--second, .frontPage--section3--block--third{
  background: linear-gradient(to right,#363951, #363951 50%, #fff);
  border-radius: 0;
}
.frontPage--paimentSection--div{
  display: flex;
  flex-direction: column;
  
 
  /* align-content: space-between; */
 
}
.frontPage--h1{
  margin: auto;
  margin-top: 30px;
  margin-bottom: 10px;
  width: 80%;
  text-align: center;
}
.icon--inCenter{
  margin: 20px 0;
  display: flex;
  align-self:center;
 
}
.main--reasons{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 33.3%;
}
.line{
  width: 75%;
  border-top: #3639512f solid 1px;
  display: flex;
  align-self: center;
  margin-top: 30px;
  margin-bottom: 10px;
}

.corner-ribbon {
  position: relative;
}

.corner-ribbon__inner {
  /* Displayed at the top left corner */
  left: 0px;
  position: absolute;
  top: 0px;

  /* Size */
  height: 8rem;
  width: 10rem;

  /* Hide the part of its children which is displayed outside */
  overflow: hidden;
}

.corner-ribbon__ribbon {
  /* Position */
  left: -10px;
  position: absolute;
  top: 35px;

  /* Size */
  height: 2.5rem;
  width: 12rem;

  /* Displayed diagonally */
  transform: translate(-38px, -8px) rotate(-45deg);

  /* Background color */
  background-color: #363951;
  color: white;

  /* Centerize the text content */
  text-align: center;
}
.terms--header{
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80%;
  text-align: center;
}
.terms--body{
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90%;
  text-align: left;
}
td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.popup--window{
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  background-color: white;
  border-radius: 16px;
  padding: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  
  width: 70%;
  height: 6rem;
  z-index: 10;
}
.popup--message{
  margin: auto auto;
  
  width: 80%;
  text-align: center;
}
.footer--two--columns{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.footer--links{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.delete--accoutn--btn{
  font-size: 0.8em;
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  /* display: block;
  text-align: right; */
  border: solid black 1px;
  width: 10rem;
}
.delete--accoutn--btn:hover{
  color:red;
  cursor:pointer;
}
.delete--account--checkbox{
  margin-right: 1rem;
}
.searchFilerSort{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0;
}
.searchFilerSort--item{
  display: flex;
  justify-content: row;
  align-items: center;
  
}
/* .searchFilerSort--item:hover{
  display: flex;
  justify-content: row;
  align-items: center;
  background-color: #363951;
  color:white;
  border-radius: 20px;
  transition: 0.5s;
  cursor: pointer;
} */
.searchFilerSort--icon{
  margin: 10px 15px;
  width: 40px;
  height: 35px;
  /* border: solid 1px black; */
  border-radius: 20px;
  transition: 0.5s;
  cursor: pointer;
}
.searchFilerSort--icon:hover{
  margin: 10px 15px;
  border-radius: 16px;
  transition: 0.5s;
  background-color: #363951;
  color:white;
  cursor: pointer;
}
.searchFilerSort--text{
  margin-right: 15px;
}
.searchFilerSort--dropdown{
  position: absolute;
  /* left: 0; */
  right: 0;
  margin: 0 2rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  background-color: white;
  border-radius: 16px;
  padding: 1rem;
  border: 1px solid #dbdbdb;

  width: 250px;

}
.searchFilerSort--dropdown--row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.searchFilerSort--dropdown--date{
  margin: 0.5rem;

}
.searchFilerSort--dropdown--date--viewall{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form-control {
  
  /* display: grid;
  grid-template-columns: 1em auto;
  gap: 1em; */
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-left: 2rem;
  margin-right: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  line-height: 1.1;
  
  

  
}
.sortFilterResult{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.sortFilterResultButton{
  margin: 5px;
 padding: 0 10px;
 border-radius: 10px;
  /* height: 30px; */
  background-color: #363951;
  color:white;
}
.searchInput{
  line-height: 30px;
  font-size: large;
  font-family: inherit;
  border: #363951 2px solid;
  border-radius: 10px;
  width: 100%;
}
.btn--reset {
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  border: none;
  /* padding: 0; */
  /* margin-top: 20px; */
}
.pricingPlans{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.instructions {
  padding: 0 1rem; /* Adds horizontal padding */
}
.instructionParagraph {
  margin-bottom: 1rem;
}
.instructionImage {
  width: 100%; /* Adjust as needed */
  max-width: 500px; /* Optional: Set a max width */
  margin: 1rem 0; /* Add space around images */
  display: block;
}

@media screen and (max-width: 600px) {

  .main{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    
  }
  .icon--close--edit{
    position: absolute;
    margin: 13% 27%;
    color: white;
    z-index: 1;
  }
  .frontPage--section1{
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 600px; */
    margin: auto;
    background: #363951;
    color: white;
    background: linear-gradient(
      to bottom,
      #363951 0%,
      #363951 50%,
      white 50%,
      white 100%
    );
    
  }
  .frontPage--section1--div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .frontPage--section1--img1{
    width: 90%;
    margin: auto;
    margin-top: 20px;
    
    align-self: flex-end;
  }
  .frontPage--section1--text1{
    margin: 0;
    text-align: center;
    width: 100%;
    font-weight: 400;

  }
  .frontPage--section1--logo{
    width: 50%;
    color:white;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .frontPage--section2{
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 100%;
    margin: 0;
    /* margin-left: 20px; */
    /* margin-top: 30%; */
  }
  .frontPage--badgeContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    margin: auto;
    /* padding-top:  20px;
    padding-bottom: 0; */
    /* border: #363951 solid 2px; */
  }
  .frontPage--section2--img1{
    
    width: 90%;
   
    /* text-align: center; */
    align-self: flex-end;
    margin-bottom: 15px;
    margin-top: 10px;
    margin-left: 5%;
    
  }

  .frontPage--section2--text1{
    align-self: center;
    margin-left: 40%;
    
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .frontPage--section2--text2{
    width: 90%;
    text-align: center;
    margin:  auto;
    align-self: flex-end;
    /* margin-bottom: 15px; */

  }


  .frontPage--section3{
    display: flex;
    align-self: center;
    flex-direction: column;
    width: 100%;
    margin: 0;
    /* margin-left: 20px; */
    
  }

  .frontPage--section3--text1{
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    align-self: center;
  }
  .frontPage--section3--block{
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
   
    width: 90%;
    /* border: #333 1px solid; */
    align-self: center;
    padding: 10px;
  }
  .frontPage--section3--block--first{
    margin: 0;
    color: white;
    text-align: center;
    width: 30%;
    background-color: #363951;
    align-self: center;
   
    font-weight: 400;
  }
  .frontPage--section3--block--second{
    margin: 0;
    color: white;
    text-align: center;
    width: 60%;
    background-color: #363951;
    align-self: center;
    
    font-weight: 400;
  }
  .frontPage--section3--block--third{
    margin: 0;
    color: white;
    text-align: center;
    width: 100%;
    background-color: #363951;
    align-self: center;
   
    font-weight: 400;
  }
  .text-howToUse{
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  .img--rad{
    border-radius: 16px;
  }
  .main--reasons{
    display: flex;
    flex-direction: column;
    
    justify-content: flex-start;
   
    width: 100%;
    
  }
  .footer--links{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: flex-end;
  }
  .pricingPlans{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (min-width: 601px) {
  .main{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .icon--close--edit{
    position: absolute;
    margin: 150px 250px;
    color: white;
    z-index: 1;
  }
  .frontPage--section1{
    display: flex;
    flex-direction: row;
    width: 100%;
    /* height: 600px; */
    margin: auto;
    background: #363951;
    color: white;
    align-items: center;

    
  }
  .frontPage--section1--div{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .frontPage--section1--img1{
    width: 40%;
    margin: auto;
    /* margin-top: 20px; */
    
    align-self: flex-end;
    margin: 20px;
  }
  .frontPage--section1--text1{
    margin: 0;
    text-align: center;
    width: 100%;
    font-weight: 400;
   

  }
  .frontPage--section1--logo{
    width: 50%;
    color:white;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .frontPage--section2{
    display: flex;
    flex-direction: row;
    align-self: center;
    width: 90%;
    margin: auto;
    margin-top: 10px;
    /* margin-left: 20px; */
    /* margin-top: 30%; */
    margin-bottom: 20px;
  }
  .frontPage--section2--div{
    display: flex;
    flex-direction: column-reverse;
    
    justify-content: flex-end;
    /* align-content: space-between; */
    width: 33.3%;
    
    
    /* border: solid 1px black; */
    /* margin-left: 20px; */
    /* margin-top: 30%; */
  }

  .frontPage--section2--img1{
    margin: 0;
    align-self: center;
    width: 90%;
   margin-bottom: 15px;
    
  }

  .frontPage--section2--text1{
    align-self: center;
    margin-left: 40%;
    
    margin-bottom: 10px;
    margin-top: 25px;
  }
  .frontPage--section2--text2{
    width: 90%;
    text-align: center;
    margin:  0;
    align-self: center;
    /* margin-bottom: 15px; */

  }


  .frontPage--section3{
    display: flex;
    align-self: center;
    flex-direction: column;
    width: 100%;
    margin: 0;
    /* margin-left: 20px; */
    
  }
  .frontPage--section3--text1{
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    align-self: center;
  }
  .frontPage--section3--block{
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 90%;
    /* border: #333 1px solid; */
    align-self: center;
    padding: 10px;
  }
  .frontPage--section3--block--first{
    margin: 0;
    color: white;
    text-align: center;
    width: 30%;
    background-color: #363951;
    align-self: center;
   
    font-weight: 400;
  }
  .frontPage--section3--block--second{
    margin: 0;
    color: white;
    text-align: center;
    width: 60%;
    background-color: #363951;
    align-self: center;
    
    font-weight: 400;
  }
  .frontPage--section3--block--third{
    margin: 0;
    color: white;
    text-align: center;
    width: 100%;
    background-color: #363951;
    align-self: center;
    
    font-weight: 400;
  }
  .text-howToUse{
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  .img--rad{
    border-radius: 16px;
  }

}


.footer{
  /* margin-top: 30px; */
  background-color: #363951;
  background: linear-gradient(to bottom, white, #363951 1%, #363951 100%);
  color: white;
  padding: 20px;
}
.footer--text{
  color: white;
  font-weight: 500;
}
.footer--icon{
  margin: 10px;
  padding: 10px;
  color: white;
  border: solid 1px rgb(88, 88, 88);
  border-radius: 10px;
}

.footer--link{
  color: white;
  padding-right: 1rem;
}
.footer--link:active{
  color: white;
}
.footer--link:link{
  color: white;
}
.footer--link:visited{
  color: white;
}
.footer--link:hover{
  color: white;
}
.copyright{
  width: 100%;
  padding: 0;
  margin-top: 1.5em;
  list-style: none;
  font-size: 0.8em;
  
  color: white;
}


/* //////////////////////////////////toggle */
.toggle--main{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #2196F3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* //////////////////////////////////toggle END*/

.roteateStar{
  position: relative;
  left: 0px;
  /* margin: 10px; */
  /* width: 0; */
}
/* //////////////////////////////////PRICING CARDS*/
.pricingCard{
  /* border:#363951 1px solid; */
  padding: 20px;
  margin: 20px;
  min-width: 320px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.pricingCardPicked{
  background-color: #3639512f;
}

/* Rotation */
.rotatioHeader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
}
.rotationLastDate{
  padding: 0px 10px;
  margin: 10px 10px;
  margin-top: 30px;
  width: 120px;
  height: 50px;
  font-size: 0.8rem;
  /* border-radius: 16px; */
  border: solid #363951 2px;
  border-left: 0px;
  border-right: 0px;
  position: relative;
  bottom: 5px;
}
.rotationLastDateText{
  margin:0 auto;
  padding: 0 3px;
}
.rotatioHeaderTextInFrame{
  font-size: 1rem;
  padding-left: 5px;
  position: relative;
  bottom: 5px;
}
.rotatioHeaderButton{
  position: relative;
  top: 5px;
  height: 40px;
  width: 120px;
  border: solid #363951 2px;
  margin-bottom: 10px;

}
.settingsRotationReminder{
/* background: rgba(255, 128, 0, 0.277) !important;
border-radius: 16px; */
}
.printBaskets{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px;
}
.basket{
  border: 1px solid #dbdbdb;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px auto;
  border-radius: 16px;
  color: #333;
  width:300px;
  /* height: 300px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  align-items: center;
}
.basketTitle{
  /* margin: 5px auto; */
  text-align: center;
  font-size: 2rem;
}
.basketIcon{
  margin: 5px auto;
}

.openedBasket{

  width: 90%;
  
  display: flex;
  flex-direction: column;
  margin: auto;
  /* align-items: center; */
}
.listOfToysInABasket{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.listOfToysInABasketHeader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem;
}
.basketNameInAList{
  font-size: 1.5rem;
}
.basketsListText{
  font-size: 1.5rem;
  margin: 1rem  auto;
}
.basketsListInfo{
  margin: 0 auto;
  text-align: center;
}
.basketsListComment{
  margin: 0 auto 1rem auto;
  text-align: center;
}
.iconInABasketBack{
  cursor: pointer;
  border: 1px solid #dbdbdb;
  border-radius: 25px;
  background-color: #363951;
  color: white;
}
.iconInABasketBottom{
  cursor: pointer;
  border: 1px solid #dbdbdb;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  margin: 20px;
  background-color: #363951;
  color: white;
}
.iconInABasketBottom:active{
  cursor: pointer;
  border: 1px solid #dbdbdb;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  margin: 20px;
  background-color: rgba(253, 98, 98, 0.9);
}
.basketsPage{
  display: flex;
  flex-direction: column;
}
.listOfToysInABasketFooter{
  /* border: #2196F3 1px solid; */
  position: fixed;
  bottom: 0;
  left: 0;
  /* left: calc((100vw - 1200px)/2); */
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.selectedCardInABasket{
  background-color: #2196F3;
}
.basketAddWindow{
  position: fixed;
  left: 5%;
  top:5%;
  width: 90%;
  height: 90%;
  /* border: solid 2px black; */
  border-radius: 16px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 640px;
  /* align-items: center; */
}
.basketAddWindowList{

  /* border: 1px solid #dbdbdb;
  border-radius: 10px; */
  max-width: 600px;
  margin: 0 2rem;
  /* padding-left: 2rem;
  padding-top: 1rem; */
  height: 90%;
  overflow-y: scroll;
}
.basketAddWindowHeader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem;
}
.basketAddWindowHeaderBasketName{
  margin-top: 0.4rem;
  font-size: 1.5rem;
}
.basketAddWindowButton{
  margin: 1rem auto;
}
.basketAddWindowSearch{
  margin: 1rem auto;
  font-size: x-large;
  width: 80%;
  border: solid 2px #363951;
  border-radius: 10px;
}
.title--block{
  font-size: large;
  margin-bottom: 5px;
  font-weight: 500;
}
.successPayment--box{
  display: flex;
  width: 300px;
  height: 300px;
  background-color: white;
  border: 1px solid #363951;
 
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.payment--text{
  color:#363951;
  /* margin: 20px; */
  font-weight: 400;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
}

.deletionForm--checkbox--container{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.deletionForm--checkbox{
  width: 20px;
  height: 20px;
  padding-left: 20px;
  padding: 0;
}
.deletionForm--checkbox--label{
  padding-left: 20px;
}
.successText{
  color: white;
  background-color: #049063;
  padding: 10px;
  border-radius: 16px;
  margin-bottom: 10px;
  align-self: center;
  margin-top: 10px;
}









@media screen and (min-width: 1200px) {
  body{
    width: 1200px;
    margin: auto;
  }
  .icon--close--edit{
    position: absolute;
    margin: 150px 250px;
    color: white;
    z-index: 1;
  }
  .listOfToysInABasketFooter{
 
    left: calc((100vw - 1200px)/2);
    
  }
  }